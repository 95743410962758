<template>
    <div :class="`m-3 p-2 wrapper wrapper--border wrapper--default`">
        <h3 class="text-center">Openstaande inruilers</h3>
        <hr>
        <Loading v-if="loading" />
        <table v-else :class="`w-100 table--perweek table__border--default`">
            <thead :class="`table__head--default`">
                <th class="table__head--text">Order</th>
                <th class="table__head--text">Klant</th>
                <th class="table__head--text">Klantnaam</th>
                <th class="table__head--text">Verkoper</th>
                <th class="table__head--text">Soort</th>
                <th class="table__head--text">Opbouw</th>
                <th class="table__head--text">Typetekst</th>
                <th class="table__head--text">Kenteken</th>
                <th class="table__head--text">Chassis</th>
            </thead>
            <tbody>
                <tr v-for="(item, key) in open_tradeins" :key="key" class="`table__row--color`">
                    <td class="table__cell--default"> <router-link :to="{
                        name: 'verkooporderdetails',
                        params: { id: item.Ordernummer },
                    }">{{ item.Ordernummer }}</router-link></td>
                    <td class="table__cell--default">
                        <router-link :to="{
                            name: 'klantpagina',
                            params: { id: item.klant },
                        }">{{ item.klant }}</router-link>
                    </td>
                    <td class="table__cell--default">{{ item.klantnaam }}</td>
                    <td class="table__cell--default">{{ item.Verkopernaam }}</td>
                    <td class="table__cell--default">{{ item.Soort }}</td>
                    <td class="table__cell--default">{{ item.Opbouw }}</td>
                    <td class="table__cell--default">{{ item.Typetekst }}</td>
                    <td class="table__cell--default">{{ item.Kenteken }}</td>
                    <td class="table__cell--default">{{ item.chassisnummer }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script>
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";

export default {
    components: { Loading },
    data: () => ({
        loading: true,
        open_tradeins: null,
    }),
    created() {
        this.getData();
    },
    methods: {
        getData() {
            request(`open-tradeins`, "GET").then(
                ({ open_tradeins }) => {
                    this.open_tradeins = open_tradeins;
                    this.loading = false;
                }
            );
        },
    },
};
</script>