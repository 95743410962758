var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`m-3 p-2 wrapper wrapper--border wrapper--default`},[_c('h3',{staticClass:"text-center"},[_vm._v("Openstaande inruilers")]),_c('hr'),(_vm.loading)?_c('Loading'):_c('table',{class:`w-100 table--perweek table__border--default`},[_c('thead',{class:`table__head--default`},[_c('th',{staticClass:"table__head--text"},[_vm._v("Order")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Klant")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Klantnaam")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Verkoper")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Soort")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Opbouw")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Typetekst")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Kenteken")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Chassis")])]),_c('tbody',_vm._l((_vm.open_tradeins),function(item,key){return _c('tr',{key:key,staticClass:"`table__row--color`"},[_c('td',{staticClass:"table__cell--default"},[_c('router-link',{attrs:{"to":{
                    name: 'verkooporderdetails',
                    params: { id: item.Ordernummer },
                }}},[_vm._v(_vm._s(item.Ordernummer))])],1),_c('td',{staticClass:"table__cell--default"},[_c('router-link',{attrs:{"to":{
                        name: 'klantpagina',
                        params: { id: item.klant },
                    }}},[_vm._v(_vm._s(item.klant))])],1),_c('td',{staticClass:"table__cell--default"},[_vm._v(_vm._s(item.klantnaam))]),_c('td',{staticClass:"table__cell--default"},[_vm._v(_vm._s(item.Verkopernaam))]),_c('td',{staticClass:"table__cell--default"},[_vm._v(_vm._s(item.Soort))]),_c('td',{staticClass:"table__cell--default"},[_vm._v(_vm._s(item.Opbouw))]),_c('td',{staticClass:"table__cell--default"},[_vm._v(_vm._s(item.Typetekst))]),_c('td',{staticClass:"table__cell--default"},[_vm._v(_vm._s(item.Kenteken))]),_c('td',{staticClass:"table__cell--default"},[_vm._v(_vm._s(item.chassisnummer))])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }